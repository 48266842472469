import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


const WelcomeToBendable = (props) => {
  const {
    height = '100%',
  } = props;

  return (
    <Box
      sx={{
        opacity: 99.9,
        // display: 'flex',
        // flexDirection: 'column',
        width: '100%',
        height: height,
        // flexGrow: 1,
        background: '#E9AD21',
        paddingTop: { xs:'31px', lg: '129px' },
        paddingBottom: { xs:'53px', lg: '156px' },
        paddingLeft: { xs:'26px', lg: '99px' },
        paddingRight: { xs:'26px', lg: '80px' },
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Cooper Std',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: { xs:'30px', lg: '54px' },
          lineHeight: { xs:'40px', lg: '60px' },
          letterSpacing: '-1.125px',
          color: '#FFFFFF',
          maxWidth: { xs:'100%', lg: '550px', xl: '1200px' },
          paddingBottom: { xs:'21px', lg: '60px' },
        }}
      >
        Welcome to the Bendable network!
      </Typography>

      <Typography
        sx={{
          fontFamily: 'Sharp Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: { xs:'16px', sm: '24px', lg: '30px' },
          lineHeight: { xs:'24px', sm: '32px', lg: '40px' },
          letterSpacing: '-1.125px',
          color: '#5F6468',
          // width: { xs:'310px', lg: '483px', xl: '600px' },
          paddingBottom: { xs:'0px', lg: '61px' },
        }}
      >
        We’re so delighted you’re ready to be part of our next Bendable cohort. The onboarding process takes about three months. During that time, we will work with your library to make sure that your catalog of learning resources is just right for your patrons.
      </Typography>

    </Box>
  );
};

export default WelcomeToBendable;
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Box from '@mui/material/Box';

const PhonesWithBendable = (props) => {

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: { xs: '167px', lg: '100%' },
        flexGrow: 1,
      }}
    >
      <StaticImage src="../../images/phonesWithBendable.png" alt="" />

    </Box>
  );
};

export default PhonesWithBendable;
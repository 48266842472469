import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

import Layout from '../components/layout';
import PrimaryAppBar from '../components/appBar/PrimaryAppBar';

import WelcomeToBendable from '../components/librariesPage/welcomeToBendable';
import PhonesWithBendable from '../components/librariesPage/phonesWithBendable';

import Footer from '../components/footer/Footer';
import WellBeInTouch from '../components/librariesPage/wellBeInTouch';
import QuestionsOrConcerns from '../components/librariesPage/questionsOrConcerns';
import BendableHeroPortraitBG from '../components/librariesPage/bendableHeroPortraitBG';
import StepToGuarantee from '../components/librariesPage/stepToGuarantee';
import ReserveYourSpotForm from '../components/librariesPage/reserveYourSpotForm';


const ReserveMySpotPage = (props) => {
  const { location } = props;
  console.log('ReserveMyPage location: ', location);

  const handleScheduleConversation = () => {
    console.log('libraries.handleScheduleConversation() called...');
    setTimeout(() => {
      const el = document.getElementById('contactFormWrapper');
      if (el) el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 250);
  };

  const handleDownloadBrochure = () => {
    console.log('libraries.handleDownloadBrochure() called...');

  };

  return (
    <Layout pageTitle="Bendable for Libraries">
      <PrimaryAppBar instanceName="" backPath="/libraries" />

      <Box id="appBarCompensation" sx={{
        height: { xs: '58px', lg: '90px' },
      }} />

      {/* HERO ROW */}
      <Stack
        direction={{ xs: 'column', lg: 'row' }}
        // sx={{
        //   height: { xs: '350px', md: '500px', lg: '900px' },
        // }}
      >
        <WelcomeToBendable height={{ xs: '350px', lg: '800px' }} />
        <BendableHeroPortraitBG imageUrl="https://bendable.s3.us-west-1.amazonaws.com/b4l_potter_centered.jpg" />

      </Stack>

      {/* FORM */}
      <ReserveYourSpotForm />

      {/* HEADING */}
      <WellBeInTouch />

      {/* ROW 1 */}
      <StepToGuarantee 
        number="1" 
        heading="Signed statement of work returned to the Drucker Institute" 
        subheading="No later than May 31" 
      />

      {/* ROW 2 */}
      <StepToGuarantee 
        number="2" 
        heading="Bendable stand-up fee and Year 1 payment due" 
        subheading="No later than June 30" 
      />

      {/* ROW 3 */}
      <StepToGuarantee 
        number="3" 
        heading="Virtual onboarding meeting" 
        subheading="No later than August 1" 
      />

      {/* ROW 4 */}
      <StepToGuarantee 
        number="4" 
        heading="Launch of your Bendable" 
        subheading="Fall 2022" 
      />

      <Box id="spacer1" sx={{
        height: { xs: '20px', lg: '60px' },
      }} />


      {/* ROW 3 */}
      <QuestionsOrConcerns />

      <Footer />
    </Layout>
  )
}

export default ReserveMySpotPage;